<template lang='pug'>
	.container
		.wallet-form-box.card
			.card-body
				router-link.btn.btn-close(to='/wallet')
					i.fa.fa-times
				h4 Dishhome Bill Payment
				transition(name='fade' mode='out-in')
					.form-group(v-if='message')
						.show-message {{message}}
				.row
					.col-md-7
						form(@submit.prevent="makePayment" ref='formRef')
							.form-group
								label Customer Id
								input.form-control(type='text' v-model='dishHome.chipId' required)
							.form-group
								label Amount to Pay (Rs.)
								select.form-control( v-model="dishHome.amount" required)
									option(:value='null' disabled) Select Amount
									option(v-for='amount in feasibleAmountList' :value='amount') NPR {{amount}}
							div
								show-bank(v-if='banks' :banks='banks' :bankClicked="bankClicked")
					.col-md-5
						pin-confirm(v-if='paymentModal' :previewDetails='previewDetails' :pinVerification='verifyPin')
		loading-spinner(:loading='loading')
</template>

<script>
import ShowBank from "@/components/ShowBank";
import PinConfirm from "@/components/PinConfirm";
import LoadingSpinner from "@/components/LoadingSpinner";
import api from "@/helpers/api";
import payment from "@/mixins/payment";
import axios from "@/axios"

export default {
  mixins: [payment],
  name: "Dishome",
  components: { ShowBank, LoadingSpinner, PinConfirm },
  computed: {
    previewDetails() {
      return {
        "Customer ID": this.dishHome.chipId,
        "Total Amount to Pay": "Rs. " + this.dishHome.amount,
        "Total Reward Amount": "Rs. " + this.dishHome.reward
      };
    }
  },
  async mounted() {
    this.banks = await api.banks();
    this.dishHome.servicesId = await api.serviceIdBySlug("DISHHOME");
    this.loading = false;
  },
  data() {
    return {
      banks: "",
      dishHome: {
        chipId: "",
        servicesId: "5cf61fe061fd061f8056b3d9",
        amount: null,
        bank: "",
        reward: 0,
        requestType: "web"
      },
      feasibleAmountList: [
        350,
        400,
        500,
        600,
        700,
        1000,
        2000,
        3000,
        4000,
        5000,
        6000,
        7000,
        9000,
        10000
      ],
      message: "",
      loading: true
    };
  },
  methods: {
    verifyPin(verified) {
      this.pinVerification(this.makePayment, verified);
    },
    bankClicked(bank) {
      if (this.dishHome.amount && this.$refs.formRef.reportValidity()) {
        this.dishHome.bank = bank;
      } else {
        this.message = "Please select amount.";
      }
    },
    makePayment() {
      if (this.pinVerified) {
        this.loading = true;
        axios
          .get("services/billpayment/dishhome", {
            params: this.dishHome
          })
          .then(r => {
            if (r.data.data.userDetails) {
              axios
                .post("services/billpayment/dishhome/payment", this.dishHome)
                .then(res => {
                  if (res.data) {
                    if (res.data.data.message) {
                      this.message = res.data.data.message;
                      this.dishHome.amount = "";
                      this.pinVerified = false;
                      if (res.data.code != 0)
                        this.$store.dispatch("UPDATE_BALANCE");
                    } else {
                      location.replace(res.data.data.apiurl);
                    }
                    this.loading = false;
                  }
                });
            } else {
              this.message = "Invalid Customer ID";
              this.loading = false;
            }
          }).catch(_ => {
            this.message = "Something went wrong"
            this.loading = false
          });
      } else {
        this.paymentModal = true;

        axios
          .get("rewards/calculate", {
            params: {
              amount: this.dishHome.amount,
              servicesId: this.dishHome.servicesId
            }
          })
          .then(res => {
            if (res.data && res.data.data) {
              this.loading = false;
              this.dishHome.reward = res.data.data.earningRewards;
            }
          });
      }
    }
  }
};
</script>
